import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import styled from 'styled-components'
import GridContainer from 'components/atoms/grid-container'
import { Headline } from '@typeform/ginger/dist/components/typography'

type TTextProps = {
  layout: string
  hasMedia: boolean
}

export const Container = styled(GridContainer)<{ background?: string }>`
  grid-column: 1 / -1;
  padding: 64px 0;
  align-items: center;
  ${(props: { background?: string }) =>
    props.background ? `background: ${props.background};` : ''}

  ${mediaQueries.sm`
    grid-column: 2 / 8;
    padding: 96px 0;
  `};

  ${mediaQueries.md`
    grid-column: 2 / 12;
  `};
`

export const Text = styled.div<TTextProps>`
  grid-column: 1 / -1;
  align-self: flex-start;
  grid-row: 2;

  ${mediaQueries.sm`
  ${(props: TTextProps) =>
    props.hasMedia ? `grid-column: 2 / 8; text-align: center;` : ''}
  `};

  ${mediaQueries.md`
    ${(props: TTextProps) =>
      props.hasMedia ? `grid-column: ${props.layout}; text-align: left;` : ''}
  `};
`

export const Title = styled(Headline)`
  margin-bottom: ${GRID_UNIT * 2}px;
  grid-row: 1;
  grid-column: 1 / -1;
  text-align: center;
  ${mediaQueries.md`
    margin-bottom: ${GRID_UNIT * 10}px;
  `};
`
