import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import React, { useEffect, useState, useCallback, useRef } from 'react'

import {
  AccordionWrapper,
  AccordionHeading,
  AccordionTitle,
  AccordionContent,
  AccordionText,
  AccordionCollapser,
  AccordionIcon,
} from './accordion-item.styles'
import { TAccordionItem } from './types'

const ACCORDION_CONTENT_DEFAULT_HEIGHT = 'auto'

const ARROW_PATH =
  'm8.16 4.55 4.12 4.11a2.86 2.86 0 0 0 4.04 0L8.16.5 0 8.66a2.86 2.86 0 0 0 4.05 0l4.11-4.11Z'

export const AccordionItem = ({
  item,
  isExpanded,
  handleItemClick,
  hasMedia,
  index,
}: TAccordionItem) => {
  const contentRef = useRef<HTMLInputElement>(null)
  const { title, text } = item
  const [contentHeight, setContentHeight] = useState(
    ACCORDION_CONTENT_DEFAULT_HEIGHT
  )

  const handleTransitionEnd = useCallback(() => {
    setContentHeight(ACCORDION_CONTENT_DEFAULT_HEIGHT)
  }, [setContentHeight])

  useEffect(() => {
    const contentRefScrollHeight = contentRef?.current?.scrollHeight || '0'
    setContentHeight(isExpanded ? `${contentRefScrollHeight}px` : '0px')

    if (isExpanded) {
      window.addEventListener('transitionend', handleTransitionEnd)

      return () => {
        window.removeEventListener('transitionend', handleTransitionEnd)
      }
    }
  }, [isExpanded, handleTransitionEnd])

  const handleClick = useCallback(
    (event: React.SyntheticEvent) => {
      const action = isExpanded ? 'close' : 'open'
      event.preventDefault()
      handleItemClick(item, action, index, event)
    },
    [handleItemClick, index, isExpanded, item]
  )

  return (
    <AccordionWrapper
      isExpanded={isExpanded}
      hasMedia={hasMedia}
      {...(isExpanded ? { open: true } : {})}
    >
      <AccordionCollapser onClick={handleClick}>
        <AccordionHeading isExpanded={isExpanded} hasMedia={false}>
          <AccordionIcon isExpanded={isExpanded}>
            <path fill={NeutralColors.Ink} d={ARROW_PATH} />
          </AccordionIcon>
          <AccordionTitle>{title}</AccordionTitle>
        </AccordionHeading>
      </AccordionCollapser>
      <AccordionContent ref={contentRef} contentHeight={contentHeight}>
        <AccordionText dangerouslySetInnerHTML={{ __html: text }} />
      </AccordionContent>
    </AccordionWrapper>
  )
}

AccordionItem.displayName = 'AccordionItem'
