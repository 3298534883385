import { LAYOUT } from '@typeform/ginger/dist/constants/layout'
import React, { memo, useState, useEffect, useCallback, useMemo } from 'react'

import AccordionModule from './accordion-module'
import { Title, Text, Container } from './image-accordion-module.styles'
import { Media, MediaContainer } from './media'
import { TAccordion, TImageAccordionModuleProps } from './types'

const { IMAGE_LEFT, IMAGE_RIGHT } = LAYOUT

const LAYOUT_GRID = {
  [IMAGE_LEFT]: {
    media: '1 / 7',
    text: '7 / 13',
  },
  [IMAGE_RIGHT]: {
    media: '7 / 13',
    text: '1 / 7',
  },
}

const MAX_ITEMS = 4

/**
 * ### Overview
 * ImageAccordionModule is a AccordionModule component alongside
 * a media element that can be image or video. The AccordionModule is
 * a vertical list that contains information when opened. When a new
 * item within the list is opened, the previously-opened item is
 * closed and the new item’s content information is displayed.
 *
 * Each item in the list can have its own media (image or video)
 *
 * This implementation and design has a limit of 4 items.
 *
 * ### When to use
 *
 * Use ImageAccordionModule when you whant to consolidate information
 * within a single system alongside a media content.
 */

const ImageAccordionModule = (props: TImageAccordionModuleProps) => {
  const {
    layout = LAYOUT.IMAGE_RIGHT,
    title,
    accordion = [],
    trackExperimentEvent = undefined,
    backgroundColor = 'transparent',
    onClickItem = () => {},
    ...rest
  } = props
  const [currentAccordionId, setCurrentAccordionId] = useState<string | null>(
    accordion?.length ? accordion[0].id : null
  )
  let hasMedia = false

  if (accordion?.length) {
    hasMedia = accordion.reduce((accum, item) => {
      if (accum) {
        return true
      }
      if (!!item.image || !!item.videoLink) {
        return true
      }
      return accum
    }, false)
  }

  useEffect(() => {
    if (!accordion?.forEach) return
    // Preload any accordion images
    accordion.forEach((item: TAccordion) => {
      const imageSrc = item?.image?.src
      if (imageSrc) {
        const image = new Image()
        image.src = imageSrc
      }
    })
  }, [accordion])

  const handleClickItem = useCallback<
    NonNullable<TImageAccordionModuleProps['onClickItem']>
  >(
    (item, action, index, event) => {
      // Only allow closing an accordion if there is no media.
      if (currentAccordionId === item.id && !hasMedia) {
        setCurrentAccordionId(null)
      } else {
        setCurrentAccordionId(item.id)
      }

      if (trackExperimentEvent) {
        const { trackExperimentEventOnClick } = item
        if (trackExperimentEventOnClick) {
          trackExperimentEvent({
            name: trackExperimentEventOnClick.eventName,
          })
        }
      }
      if (onClickItem) {
        onClickItem(item, action, index, event)
      }
    },
    [currentAccordionId, hasMedia, trackExperimentEvent, onClickItem]
  )

  const currentAccordionItem = useMemo(() => {
    if (!accordion) {
      return null
    }

    return accordion.find((item: TAccordion) => item?.id === currentAccordionId)
  }, [currentAccordionId, accordion])

  if (!accordion?.length || !layout) return null

  return (
    <Container
      background={backgroundColor}
      data-qa='image-accordion-module'
      {...rest}
    >
      {title && <Title>{title}</Title>}
      {hasMedia && (
        <MediaContainer layout={LAYOUT_GRID[layout].media}>
          <Media {...currentAccordionItem} />
        </MediaContainer>
      )}
      <Text hasMedia={hasMedia} layout={LAYOUT_GRID[layout].text}>
        <AccordionModule
          items={accordion.slice(0, MAX_ITEMS)}
          onClickItem={handleClickItem}
          currentAccordionId={currentAccordionId}
        />
      </Text>
    </Container>
  )
}

ImageAccordionModule.displayName = 'ImageAccordionModule'

export default memo(ImageAccordionModule)
