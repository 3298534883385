import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  NeutralColors,
  transparent,
} from '@typeform/ginger/dist/constants/colors'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import styled from 'styled-components'

type TAccordionWrapperProps = {
  hasMedia?: boolean
  isExpanded: boolean
}

export const AccordionWrapper = styled.details`
  background-color: ${({ isExpanded }: TAccordionWrapperProps) =>
    isExpanded ? NeutralColors.Grey100 : transparent};
  text-align: left;
  border-radius: 24px;
  transition: background-color 300ms ease-in-out;
  margin-bottom: ${({ isExpanded, hasMedia }: TAccordionWrapperProps) => {
    if (!hasMedia) {
      return spacing(1)
    }
    return isExpanded ? '0' : spacing(1)
  }};
  &:hover {
    background-color: ${NeutralColors.Grey100};
  }

  padding: 32px 48px;
  ${mediaQueries.sm`
    border-radius: 32px;
    margin-bottom: 10px;
  `};
`

export const AccordionHeading = styled.button<TAccordionWrapperProps>`
  display: flex;
  align-items: center;
  margin: ${({ isExpanded }: TAccordionWrapperProps) =>
    isExpanded ? spacing(0, 0, 2) : '0'};
  cursor: ${({ isExpanded, hasMedia }) => {
    // If there is no media then items can be opened and closed.
    if (!hasMedia) {
      return 'pointer'
    }
    return isExpanded ? 'default' : 'pointer'
  }};
  border: none;
  background: none;
  width: 100%;
  font: inherit;
  padding: 0;
  color: inherit;
  text-align: left;
  ${mediaQueries.lg`
    margin: ${({ isExpanded }: TAccordionWrapperProps) =>
      isExpanded ? spacing(0, 0, 1.5) : '0'};
  `};
`
export const AccordionTitle = styled.h2`
  font-size: ${fontSize.large};
  line-height: ${lineHeight.small};
  ${mediaQueries.lg`
    font-size: ${fontSize.xLarge};
    line-height: 1.1;
  `};
  margin: 0;
`

export const AccordionContent = styled.div`
  transition: height 250ms ease-in-out;
  overflow: hidden;
  height: ${({ contentHeight }: { contentHeight: string }) => contentHeight};
  background-color: inherit;
  border-radius: inherit;
`

export const AccordionText = styled.div`
  margin-inline-start: 0;
  padding: ${spacing(0, 2, 0, 4)};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
  margin: 0;
  a {
    text-decoration: underline;
  }
  ${mediaQueries.lg`
    font-size: ${fontSize.medium};
    line-height: ${lineHeight.xSmall};
  `};
`

export const AccordionCollapser = styled.summary`
  border-radius: inherit;
  background-color: inherit;
  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }

  line-height: ${lineHeight.small};
  appearance: none;
  border: none;
  margin: 0;
  cursor: inherit;
  width: 100%;
  padding: 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AccordionIcon = styled.svg<TAccordionWrapperProps>`
  width: 16px;
  height: 9px;
  margin-right: ${spacing(2)};
  transition: transform 400ms ease-out;
  flex-shrink: 0;
  ${({ isExpanded }: TAccordionWrapperProps) =>
    isExpanded ? `transform: rotate(0)` : `transform: rotate(180deg)`};
`
