export const DEFAULT_VIDEO_ID = 'fs4eliwya'
export const IFRAME_DEFAULT_TITLE = 'VideoAsk'
export const IFRAME_AVAILABLE_FEATURES = [
  'camera',
  'microphone',
  'autoplay',
  'encrypted-media',
  'fullscreen',
  'display-capture',
]
