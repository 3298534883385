import { TEmbedOptions } from './wistia-player.types'
import { PlayerTypes } from './constants'

/**
 * Wistia embed options
 * https://wistia.com/support/developers/embed-options#options
 */
export const EmbedOptions: TEmbedOptions = {
  [PlayerTypes.DefaultFromWistia]: {
    videoFoam: true,
  },
  [PlayerTypes.VideoResponsive]: {
    autoPlay: true,
    controlsVisibleOnLoad: false,
    fullscreenButton: false,
    muted: true,
    playbar: true,
    playButton: true,
    settingsControl: false,
    silentAutoPlay: true,
    videoFoam: true,
    volumeControl: true,
  },
  [PlayerTypes.VideoBackground]: {
    autoPlay: true,
    controlsVisibleOnLoad: false,
    endVideoBehavior: 'loop',
    fitStrategy: 'cover',
    fullscreenButton: false,
    muted: true,
    playbar: false,
    playButton: false,
    settingsControl: false,
    silentAutoPlay: false,
    videoFoam: false,
    volumeControl: false,
  },
}
