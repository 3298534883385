import YoutubePlayer from 'components/molecules/youtube-player'
import WistiaPlayer from 'components/molecules/wistia-player/wistia-player'
import VideoaskPlayer from 'components/molecules/videoask-player'

export enum VideoLinkTypes {
  YouTube = 'media-youtube',
  Wistia = 'media-wistia',
  VideoAsk = 'media-videoask',
}

export const mediaPlayers = {
  [VideoLinkTypes.YouTube]: YoutubePlayer,
  [VideoLinkTypes.Wistia]: WistiaPlayer,
  [VideoLinkTypes.VideoAsk]: VideoaskPlayer,
}
