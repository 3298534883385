import {
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
  IMAGE_LOADING,
} from '@typeform/ginger/dist/constants/image'
import { memo, useEffect, useRef, useState } from 'react'
import Script from 'next/script'

import { WistiaScriptURL, PlayerTypes } from './constants'
import { EmbedOptions } from './utils'
import {
  WistiaContainer,
  WistiaThumbnailImage,
  Wrapper,
} from './wistia-player.styles'
import { TWistiaPlayerProps } from './wistia-player.types'

const WistiaPlayer = (props: TWistiaPlayerProps) => {
  const videoRef = useRef(null)
  const [isVideoReady, setIsVideoReady] = useState(false)
  const {
    videoId,
    onVideoReady = null,
    playerType = PlayerTypes.DefaultFromWistia,
    thumbnail = '',
    aspectRatio = '',
    ...customEmbedOptions
  } = props

  useEffect(() => {
    // @ts-ignore: Property '_wq' does not exist on type 'Window & typeof globalThis'
    if (videoRef.current && window._wq) {
      // @ts-ignore: Property '_wq' does not exist on type 'Window & typeof globalThis'
      videoRef.current.replaceWith(videoId)
      return
    }

    // @ts-ignore: Property '_wq' does not exist on type 'Window & typeof globalThis'
    window._wq = window._wq || []
    // @ts-ignore: Property '_wq' does not exist on type 'Window & typeof globalThis'
    window.wistiaDisableMux = true

    const embedOptionsByType =
      EmbedOptions[playerType] || EmbedOptions[PlayerTypes.DefaultFromWistia]

    // @ts-ignore: Property '_wq' does not exist on type 'Window & typeof globalThis'
    window._wq.push({
      id: videoId,
      options: {
        ...embedOptionsByType,
        ...customEmbedOptions,
      },
      // @ts-ignore: Unreachable code error
      onReady: video => {
        videoRef.current = video
        setIsVideoReady(true)
        onVideoReady?.(video)
      },
    })
  }, [customEmbedOptions, onVideoReady, playerType, videoId])

  return (
    <WistiaContainer aspectRatio={aspectRatio}>
      {!isVideoReady && thumbnail && (
        <WistiaThumbnailImage
          src={thumbnail}
          loading={IMAGE_LOADING.lazy}
          decoding={IMAGE_DECODING.async}
          // @ts-expect-error fetchpriority is not yet defined in HTMLLinkElement
          fetchpriority={IMAGE_FETCHPRIORITY.low}
          role='presentation'
        />
      )}
      <Script
        data-testid='wistia-player'
        src={WistiaScriptURL}
        strategy='lazyOnload'
      />
      {playerType !== PlayerTypes.VideoBackground ? (
        <Wrapper
          className='wistia_responsive_padding'
          data-testid='wrapper-default'
          aspectRatio={aspectRatio}
        >
          <div
            className={`wistia_embed wistia_async_${videoId} googleAnalytics=false`}
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            &nbsp;
          </div>
        </Wrapper>
      ) : (
        <Wrapper
          className={`wistia_embed wistia_async_${videoId} googleAnalytics=false`}
          data-testid='wrapper-video-background'
          disableInteraction
        >
          &nbsp;
        </Wrapper>
      )}
    </WistiaContainer>
  )
}

WistiaPlayer.displayName = 'WistiaPlayer'

export default memo(WistiaPlayer)
