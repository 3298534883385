import React, { memo } from 'react'
import { TYoutubePlayerProps } from 'components/molecules/youtube-player/types'

import { EmbedContainer } from './youtube-player.styles'
const YOUTUBE_BASE_URL = 'https://www.youtube.com/embed'

const getSrcDoc = (
  url: string,
  { videoId, name = '' }: TYoutubePlayerProps
) => `\
<style>\
  *{padding:0;margin:0;overflow:hidden}\
  html,body{height:100%}\
  img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}\
  span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}\
</style>\
<a href=${url}>\
  <img src=https://img.youtube.com/vi/${videoId}/maxresdefault.jpg alt='${name}'>\
  <span>&#x25BA;</span>\
</a>`

/**
 * Accepts embed parameters as specified here:
 * Documentation: https://developers.google.com/youtube/player_parameters#Parameters
 */
const YouTubePlayer = (props: TYoutubePlayerProps) => {
  const {
    embedParameters,
    lazyLoad = false,
    name = 'Typeform',
    videoId,
    ...rest
  } = props

  const queryString = Object.entries(embedParameters).reduce(
    (prev, [key, value]) => (!String(value) ? prev : `${prev}${key}=${value}&`),
    ''
  )

  const url = `${YOUTUBE_BASE_URL}/${videoId}?${queryString}`
  const srcDoc = lazyLoad ? getSrcDoc(url, props) : undefined

  return (
    <EmbedContainer {...rest}>
      <iframe
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        frameBorder={0}
        loading='lazy'
        src={url}
        srcDoc={srcDoc}
        title={name}
      />
    </EmbedContainer>
  )
}

YouTubePlayer.displayName = 'YouTubePlayer'

export default memo(YouTubePlayer)
