import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'
import { ImageWithLazyAttributes } from 'components/atoms/images'

export const BaseMediaContainer = styled.div`
  text-align: center;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  grid-row: 2;
  align-items: center;
  font-size: 0;
`

export const MediaContainer = styled(BaseMediaContainer)<{ layout: string }>`
  margin: 0;
  display: none;
  grid-column: ${({ layout }) => layout};
  align-self: flex-start;
  align-items: flex-start;
  ${mediaQueries.md`
    display: inherit;
  `};
  width: 100%;
  height: auto;
`

export const MobileMediaContainer = styled(BaseMediaContainer)<{
  isExpanded: boolean
}>`
  grid-column: 1 / -1;
  transition: max-height 300ms ease-in-out;
  max-height: ${({ isExpanded }) => (isExpanded ? '500px' : '0')};
  margin: ${({ isExpanded }) => (isExpanded ? '40px auto' : '0')};
  ${mediaQueries.md`
    display: none;
  `};
`

export const Image = styled(ImageWithLazyAttributes)`
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  height: auto;
  top: 0;

  ${mediaQueries.md`
    height: 100%;
  `}
`

export const Video = styled.video`
  max-width: 100%;
`
