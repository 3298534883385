import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{
  aspectRatio?: string
  disableInteraction?: boolean
}>`
  height: ${({ aspectRatio }) => (aspectRatio ? 'auto' : '100%')};
  position: relative;
  width: 100%;
  ${({ disableInteraction }) => disableInteraction && 'pointer-events: none;'}
`

export const WistiaContainer = styled.div<{ aspectRatio?: string }>`
  position: relative;
  ${({ aspectRatio }) =>
    aspectRatio &&
    css`
      display: flex;
      align-items: center;
      aspect-ratio: ${aspectRatio};
    `}
`

export const WistiaThumbnailImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`
