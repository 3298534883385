import React from 'react'
import { useMainContext } from '@typeform/ginger/dist/components/main-context'

import { IFRAME_AVAILABLE_FEATURES, IFRAME_DEFAULT_TITLE } from './constants'
import { EmbedContainer } from './videoask-player.styles'

export type TVideoAskPlayer = {
  aspectRatio?: {
    x: number
    y: number
  }
  iFrameTitle?: string
  name?: string
  videoId?: string
}

const VideoAskPlayer = ({
  iFrameTitle = undefined,
  name = undefined,
  videoId,
  aspectRatio = {
    x: 4,
    y: 3,
  },
}: TVideoAskPlayer) => {
  const { sharedVariables } = useMainContext()

  return (
    <EmbedContainer aspectRatio={aspectRatio}>
      <iframe
        allow={IFRAME_AVAILABLE_FEATURES.join(' *;')}
        src={`https://www.${sharedVariables.VIDEOASK_ROOT_DOMAIN}/${videoId}`}
        title={name || iFrameTitle || IFRAME_DEFAULT_TITLE}
      />
    </EmbedContainer>
  )
}

VideoAskPlayer.displayName = 'VideoAskPlayer'

export default VideoAskPlayer
