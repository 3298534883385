import React, { memo, useCallback, Fragment } from 'react'

import { Media, MobileMediaContainer } from '../media'

import { TAccordionModuleProps } from './types'
import { AccordionModuleWrapper } from './accordion-module.styles'
import { AccordionItem } from './accordion-item'

const AccordionModule = ({
  items = [],
  onClickItem,
  currentAccordionId,
}: TAccordionModuleProps) => {
  const handleItemClick = useCallback(
    (
      item: { id: string; title: string },
      action: 'open' | 'closed',
      index: number,
      event: Event
    ) => {
      onClickItem(item, action, index, event)
    },
    [onClickItem]
  )
  return (
    <AccordionModuleWrapper>
      {items.map((accordionItem, index) => {
        const itemId = accordionItem?.id
        if (!itemId) {
          return null
        }
        const hasMedia = !!accordionItem.image || !!accordionItem.videoLink
        const isExpanded = currentAccordionId === itemId
        return (
          <Fragment key={itemId}>
            <AccordionItem
              isExpanded={isExpanded}
              handleItemClick={handleItemClick}
              hasMedia={hasMedia}
              index={index}
              item={accordionItem}
            />
            {hasMedia && (
              <MobileMediaContainer isExpanded={isExpanded}>
                <Media {...accordionItem} />
              </MobileMediaContainer>
            )}
          </Fragment>
        )
      })}
    </AccordionModuleWrapper>
  )
}

AccordionModule.defaultProps = {
  items: [],
}

AccordionModule.displayName = 'AccordionModule'

export default memo(AccordionModule)
