import {
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
  IMAGE_LOADING,
} from '@typeform/ginger/dist/constants/image'
import React from 'react'
import LazyLoad from 'components/atoms/lazy-load'
import { TContentfulMediaProps } from 'components/landing-page/contentful-media/types'
import { TYoutubePlayerProps } from 'components/molecules/youtube-player/types'
import { TWistiaPlayerProps } from 'components/molecules/wistia-player/wistia-player.types'
import { TVideoAskPlayer } from 'components/molecules/videoask-player/videoask-player'

import { TImage } from '../types'

import { mediaPlayers, VideoLinkTypes } from './constants'
import { Image } from './media.styles'
export { MobileMediaContainer, MediaContainer } from './media.styles'

const getPlayerComponent = (
  videoLink: TContentfulMediaProps['mediaProps']
): React.ReactNode => {
  if (videoLink.contentType === VideoLinkTypes.YouTube) {
    const Player = mediaPlayers[VideoLinkTypes.YouTube]
    return <Player {...(videoLink as TYoutubePlayerProps)} />
  }

  if (videoLink.contentType === VideoLinkTypes.Wistia) {
    const Player = mediaPlayers[VideoLinkTypes.Wistia]
    return <Player {...(videoLink as TWistiaPlayerProps)} />
  }

  if (videoLink.contentType === VideoLinkTypes.VideoAsk) {
    const Player = mediaPlayers[VideoLinkTypes.VideoAsk]
    return <Player {...(videoLink as TVideoAskPlayer)} />
  }

  return null
}

export const Media = ({
  videoLink = null,
  image = null,
}: {
  videoLink?: TContentfulMediaProps['mediaProps'] | null
  image?: TImage | null
}) => {
  if (videoLink) {
    const isValidLink =
      videoLink.contentType === VideoLinkTypes.YouTube ||
      videoLink.contentType === VideoLinkTypes.Wistia ||
      videoLink.contentType === VideoLinkTypes.VideoAsk

    if (isValidLink) {
      const PlayerComponent = getPlayerComponent(videoLink)

      return (
        <LazyLoad>{PlayerComponent ? <>{PlayerComponent}</> : null}</LazyLoad>
      )
    }
  }

  if (image && image.src) {
    const imageProps = {
      loading: IMAGE_LOADING.lazy,
      decoding: IMAGE_DECODING.async,
      fetchpriority: IMAGE_FETCHPRIORITY.low,
      role: 'presentation',
      ...image,
    }

    return <Image {...imageProps} />
  }

  return null
}
