import styled from 'styled-components'

import { TYoutubePlayerProps } from './types'

export const EmbedContainer = styled.div.attrs<
  Pick<TYoutubePlayerProps, 'aspectRatio'>
>(props => {
  const { aspectRatio = { x: 16, y: 9 } } = props
  const { x = 16, y = 9 } = aspectRatio
  return { aspectRatioOffset: (y / x) * 100 }
})<
  Pick<TYoutubePlayerProps, 'fullHeight'> & {
    aspectRatioOffset?: number
  }
>`
  position: relative;
  padding-bottom: ${props => props.aspectRatioOffset}%;
  height: ${props => {
    return props.fullHeight ? '100%' : '0'
  }};
  overflow: hidden;
  max-width: 100%;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
