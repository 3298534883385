import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import styled from 'styled-components'

export const EmbedContainer = styled.div<{
  aspectRatio: { x: number; y: number }
}>`
  position: relative;
  padding-bottom: ${({ aspectRatio }) =>
    (aspectRatio.y / aspectRatio.x) * 100}%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: ${BORDER_RADIUS.large};

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`
