// import { TPlayerTypes } from './wistia-player.types'

export const WistiaScriptURL = 'https://fast.wistia.com/assets/external/E-v1.js'

export enum PlayerTypes {
  // The video params are defined inside Wistia Admin
  DefaultFromWistia = 'default-from-wistia',
  // Muted, no controls, auto-play
  VideoResponsive = 'video-responsive',
  // Muted, no controls, video as background in loop
  VideoBackground = 'video-background',
}

export const DEFAULT_VIDEO_ID = 'oayizyy8pl'
